export default {
    title:'網站名稱',
    h_title: {
        shouye: '首頁',
        login: '登錄',
        hudong: '活動中心',
        xianshangkefu: '線上客服',
        chongzhi: '充值',
        erduzhuanhuan: '額度轉換',
        gerenzhongxin:'個人中心',
        gerenxinxi: '個人信息',
        bangdingshouji: '綁定手機',
        dailizhongxin: '代理中心',
        xiaoxi: '消息',
		tixian: '提現',
        baobiao_cunkuang: '存款記錄',
        baobiao_tixian: '提現記錄',
        baobiao_touzhu: '投註記錄',
        baobiao_fanshui: '返水記錄',
        baobiao_eduzhuanhuan: '額度轉換記錄',
        zhuce: '註冊',
        xiugaixhifumima: '修改支付密碼',
        huodongxiangqing: '活動詳情',
        zizhufuwu: '自助服務',
        zizhufuwu_list: '幫助中心',
        xuanzeyuyan: '選擇語言',
        bangdingyihangka: '綁定銀行卡',
		fund:'备用金',
		fundlist:'申請記錄'
    },
    common:{
        shuaxin: '刷新成功',
        weihu: '正在維護中...',
        gengduo: '更多',
        xiangxi: '詳情',
        tuichudenglu: '退出登陸',
        queren: '確認',
        quxiao: '取消',
        genghuan:'查看',
        qingshuru: '請輸入',
        zanwujilv: '暫無記錄',
        lingquchenggong: '領取成功',
        baobiao_status: ['','待審核','成功','拒絕'],
        yuan: '元',
        denglu_title: '請登陸',
        denglu_mas: '您的未登陸，無法進行操作，請您重新登錄',
        xianshangrenshu: '線上人數',
		fund_status: ['待審核','成功','拒絕']
    },
    kefu: {
        caozuozhinan: '操作指南',
        xianshangkefu : 'telegram客服',
        wx: '微信',
        tousudianhua: '投訴電話'
    },
    login: {
        username_placeholder: '請輸入賬號 *',
        username_alert: '請輸入賬號',
        password_placeholder: '請輸入密碼 *',
        password_alert: '請輸入密碼',
        password_alert_length: '密碼必須大於6位',
        wangjimima: '忘記密碼',
        zhuce: '立即註冊',
        denglu_btn : '登入',
        wangji: ['微信','在線客服']
    },
    zhuce: {
        top_right: '已有賬號，下載APP',
        username_placeholder: '請輸入賬號 *',
        username_alert: '請輸入賬號',
        password_placeholder: '請輸入密碼 *',
        password_alert: '請輸入密碼',
        password_alert_length: '密碼必須大於6位',
        password_alert_buyizhi: '兩次密碼不一致',
        password2_placeholder: '請再次輸入密碼 *',
        password2_alert: '請再次輸入密碼',
        qk_password_placeholder: '請輸入取款密碼 *',
        qk_password_alert: '請輸入取款密碼',
        qk_password_alert_length: '取款密碼必須大於6位',
        phone_placeholder: '請輸入手機號 *',
        phone_alert: '請輸入手機號',
        code_placeholder: '請輸入驗證碼 *',
        code_alert: '請輸入驗證碼',
        huoqu_btn: '獲取驗證碼',
        index: '返回首頁',
        zhuce: '註冊'
    },
    index: {
        top: ['英屬維京群島','官方運營','英屬維京群島','（bvi）認證'],
        nav:['首頁','休閑區','充值','提現','彩票','電子','視訊','棋牌','體育','捕魚','語言'],
        youxi:['您需要為【show_title】轉入金額嗎?','當前余額','回收中...','請輸入金額','全部','直接進入遊戲','立即轉入遊戲']
    },
    bottom:{
        nav: ['活動中心','線上客服','首頁','額度轉換','我的']
    },
    gerenzhongxin: {
        xiugaimima: '修改密碼',
        lingqufuli: '領取每日福利',
        dailizhongxin: '代理中心',
        zhuzhanghuyue:'主賬戶余額',
        benyuexiazhuzonge: '本月下註總額',
        chongzhi: '充值',
        fanshui: '返水',
        tixian: '提現',
        xiaoxi: '消息',
        fulihuodong: '福利活動',
        baobiao: '報表',
        shishifanshui : '實時返水',
        shishifanshui_str : '自主洗碼,實時結算,請投註達標後再試'
    },
    xiaoxi: {
        title: '消息中心',
        table: ['主題','內容','發送時間']
    },
    gerenxinxi: {
        title: '個人信息',
        bangdingshouji : '綁定手機',
        xiugaizhifumima: '修改支付密碼'
    },
    bangding: {
        title: '綁定手機',
        yibangding: '已綁定手機號',
        shoujihao: '手機號',
        yanzhengma: '驗證碼',
        jiumima: '舊密碼',
        xinmima: '新密碼',
        querenmima: '確認密碼'
    },
    jilv: {
        cunkuan: '存款',
        tixian: '提現',
        touzhu: '投註',
        fanshui: '返水',
        erdu: '額度轉換',
        riqi_title: '選擇日期',
        riqi: ['今日','2天','3天','5天','7天','30天'],
        zongchenggongtixian: '總成功充值',
        tixianchenggong: '提現成功',
        cunkuan_tab: ['金額','狀態','時間'],
        touzhu_tab: ['玩法','投註','中獎金','時間'],
        eduzhuanhuan_tab: ['金額','类型','時間']
    },
    erduzhuanhuan: {
        chonghuijinge: '重繪金額',
        zijinhuishou: '資金回收',
        zhongxinqianbao: '中心錢包',
        youxiqianbao: '遊戲錢包',
        zhuanzhang_placeholder: '請輸入轉帳金額',
        lijizhuanzhang: '立即轉帳',
        alt1 : '兩邊遊戲ID不可一致'
    },
    dailizhongxin: {
        tuiguanglianjie: '推廣鏈接',
        fuzhilianjie: '復製鏈接',
        fuzhichenggong: '復製成功 !',
        erweimatuiguang: '二維碼推廣',
        yongjinfafang: '傭金發放',
        huiyuangcunkuang :'會員存款',
        huiyuangtixian: '會員提現',
        huiyuan: '會員',
        huiyuanshuying: '會員輸贏',
        shenqinghuodong: '申請活動'
    },
    bangzhuzhongxin: {
        nav: ['活動中心','客服中心','額度轉換','個人中心']
    }
}
