export default {
    title:'网站名称',
    h_title: {
        shouye: '首页',
        login: '登录',
        hudong: '活动中心',
        xianshangkefu: '线上客服',
        chongzhi: '充值',
        erduzhuanhuan: '额度转换',
        gerenzhongxin:'个人中心',
        gerenxinxi: '个人信息',
        bangdingshouji: '绑定手机',
        dailizhongxin: '代理中心',
        xiaoxi: '消息',
        tixian: '提现',
        baobiao_cunkuang: '存款记录',
        baobiao_tixian: '提现记录',
        baobiao_touzhu: '投注记录',
        baobiao_fanshui: '返水记录',
        baobiao_eduzhuanhuan: '额度转换记录',
        zhuce: '注册',
        xiugaixhifumima: '修改支付密码',
        huodongxiangqing: '活动详情',
        zizhufuwu: '自助服务',
        zizhufuwu_list: '帮助中心',
        xuanzeyuyan: '选择语言',
        bangdingyihangka: '绑定银行卡',
		fund:'备用金',
		fundlist:'申請記錄'
    },
    common:{
        shuaxin: '刷新成功',
        weihu: '正在维护中...',
        gengduo: '更多',
        xiangxi: '详情',
        tuichudenglu: '退出登陆',
        queren: '确认',
        quxiao: '取消',
        genghuan:'查看',
        qingshuru: '请输入',
        zanwujilv: '暂无记录',
        lingquchenggong: '领取成功',
        baobiao_status: ['','待审核','成功','拒绝'],
        yuan: '元',
        denglu_title: '请登陆',
        denglu_mas: '您的未登陆，无法进行操作，请您重新登录',
        xianshangrenshu: '线上人数',
		fund_status: ['待審核','成功','拒絕']
    },
    kefu: {
        caozuozhinan: '操作指南',
        xianshangkefu : 'telegram客服',
        wx: '微信',
        tousudianhua: '投诉电话'
    },
    login: {
        username_placeholder: '请输入账号 *',
        username_alert: '请输入账号',
        password_placeholder: '请输入密码 *',
        password_alert: '请输入密码',
        password_alert_length: '密码必须大于6位',
        wangjimima: '忘记密码',
        zhuce: '立即注册',
        denglu_btn : '登入',
        wangji: ['微信','在线客服']
    },
    zhuce: {
        top_right: '已有账号，下载APP',
        username_placeholder: '请输入账号 *',
        username_alert: '请输入账号',
        password_placeholder: '请输入密码 *',
        password_alert: '请输入密码',
        password_alert_length: '密码必须大于6位',
        password_alert_buyizhi: '两次密码不一致',
        password2_placeholder: '请再次输入密码 *',
        password2_alert: '请再次输入密码',
        qk_password_placeholder: '请输入取款密码 *',
        qk_password_alert: '请输入取款密码',
        qk_password_alert_length: '取款密码必须大于6位',
        phone_placeholder: '请输入手机号 *',
        phone_alert: '请输入手机号',
        code_placeholder: '请输入验证码 *',
        code_alert: '请输入验证码',
        huoqu_btn: '获取验证码',
        index: '返回首页',
        zhuce: '注册'
    },
    index: {
        top: ['英属维京群岛','官方运营','英属维京群岛','（bvi）认证'],
        nav:['首页','休闲区','充值','提现','彩票','电子','视讯','棋牌','体育','捕鱼','语言'],
        youxi:['您需要为【show_title】转入金额吗?','当前余额','回收中...','请输入金额','全部','直接进入游戏','立即转入游戏']
    },
    bottom:{
        nav: ['活动中心','线上客服','首页','额度转换','我的']
    },
    gerenzhongxin: {
        xiugaimima: '修改密码',
        lingqufuli: '領取每日福利',
        dailizhongxin: '代理中心',
        zhuzhanghuyue:'主账户余额',
        benyuexiazhuzonge: '本月下注总额',
        chongzhi: '充值',
        fanshui: '返水',
        tixian: '提现',
        xiaoxi: '消息',
        fulihuodong: '福利活动',
        baobiao: '报表',
        shishifanshui : '实时返水',
        shishifanshui_str : '自主洗码,实时结算,请投注达标后再试'
    },
    xiaoxi: {
        title: '消息中心',
        table: ['主题','内容','发送时间']
    },
    gerenxinxi: {
        title: '个人信息',
        bangdingshouji : '绑定手机',
        xiugaizhifumima: '修改支付密码'
    },
    bangding: {
        title: '绑定手机',
        yibangding: '已绑定手机号',
        shoujihao: '手机号',
        yanzhengma: '验证码',
        jiumima: '旧密码',
        xinmima: '新密码',
        querenmima: '确认密码'
    },
    jilv: {
        cunkuan: '存款',
        tixian: '提现',
        touzhu: '投注',
        fanshui: '返水',
        erdu: '额度转换',
        riqi_title: '选择日期',
        riqi: ['今日','2天','3天','5天','7天','30天'],
        zongchenggongtixian: '总成功充值',
        tixianchenggong: '提现成功',
        cunkuan_tab: ['金额','状态','时间'],
        touzhu_tab: ['玩法','投注','中奖金','时间'],
        eduzhuanhuan_tab: ['金额','类型','时间']
    },
    erduzhuanhuan: {
        chonghuijinge: '重绘金额',
        zijinhuishou: '资金回收',
        zhongxinqianbao: '中心钱包',
        youxiqianbao: '游戏钱包',
        zhuanzhang_placeholder: '请输入转帐金额',
        lijizhuanzhang: '立即转帐',
        alt1 : '两边游戏ID不可一致'
    },
    dailizhongxin: {
        tuiguanglianjie: '推广链接',
        fuzhilianjie: '复制链接',
        fuzhichenggong: '复制成功 !',
        erweimatuiguang: '二维码推广',
        yongjinfafang: '佣金发放',
        huiyuangcunkuang :'会员存款',
        huiyuangtixian: '会员提现',
        huiyuan: '会员',
        huiyuanshuying: '会员输赢',
        shenqinghuodong: '申请活动'
    },
    bangzhuzhongxin: {
        nav: ['活动中心','客服中心','额度转换','个人中心']
    },
    bangdingyihangka: {
        xinzengyinhangka: '新增银行卡',
        xinzengzhuanhuankuai: '新增轉數快',
        xinzengzhuanhuankuaiID: '新增轉數快ID',
        qingshuru: '请输入',
        xingming: '姓名',
        yinhangmingcheng: '银行名称',
        shoukuanzhanghao: '收款账号',
        bangding: '绑定'
    }
}
