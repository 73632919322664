import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    Tabbar_index: 2,
    token: '',
    config: [],
    user: [],
    i18n: 'zh'
  },
  getters: {
  },
  mutations: {
    Tabbar_index(state,index) {    //点击切换tabbar
      state.Tabbar_index = index
    },
    token(state,index){
      state.token = index
    },
    config(state,index){
      state.config = index
    },
    user(state,index) {
      state.user = index
    },
    i18n(state,index) {
      state.i18n = index
    }
  },
  actions: {
  },
  plugins: [vuexLocal.plugin]
})
