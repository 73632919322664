import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'
import enLocale from './en'
import zhLocale from './zh'
import zhCHTLocale from './zhCHT'
Vue.use(VueI18n)

const messages = {
    en: {
        ...enUS,
        ...enLocale
    },
    zh: {
        ...zhCN,
        ...zhLocale
    },
    zhHK: {
        ...zhHK,
        ...zhCHTLocale
    }
}

const i18n = new VueI18n({
    locale: 'zh',  // 设置默认语言
    messages: messages // 设置资源文件对象
})
// 更新vant组件库本身的语言变化，支持国际化
function vantLocales (lang) {
    if (lang === 'en') {
        Locale.use(lang, enUS)
    } else if (lang === 'zh') {
        Locale.use(lang, zhCN)
    }
    else if (lang === 'zhHK') {
        Locale.use(lang, zhHK)
    }
}

export {i18n, vantLocales}
