export default {
    title:'Website name',
    h_title: {
        shouye: 'Home',
        login: 'Login',
        hudong: 'Activity',
        xianshangkefu: 'online customer service',
        chongzhi: 'recharge',
        erduzhuanhuan: 'quota conversion',
        gerenzhongxin:'mine',
        gerenxinxi: 'mine',
        bangdingshouji: 'modify password',
        dailizhongxin: 'agent center',
        xiaoxi: 'message',
		tixian: 'withdrawal',
        baobiao_cunkuang: 'deposit',
        baobiao_tixian: 'withdrawal',
        baobiao_touzhu: 'betting',
        baobiao_fanshui: 'return water',
        baobiao_eduzhuanhuan: 'quota conversion',
        zhuce: 'register',
        xiugaixhifumima: 'modify payment password',
        huodongxiangqing: 'event details',
        zizhufuwu: 'self service',
        zizhufuwu_list: 'help center',
        xuanzeyuyan: 'select language',
        bangdingyihangka: 'bind bank card'
    },
    common:{
        shuaxin: 'refresh successful',
        weihu: 'being maintained...',
        gengduo: 'more',
        xiangxi: 'details',
        tuichudenglu: 'exit Login',
        queren: 'confirm',
        quxiao: 'Cancel',
        genghuan:'see',
        qingshuru: 'please enter',
        zanwujilv: 'no record temporarily',
        lingquchenggong: 'Received successfully',
        baobiao_status: ['','Pending review','succeed','rejected'],
        yuan: 'RMB',
        denglu_title: 'Please log in',
        denglu_mas: 'Your is not logged in and cannot be operated. Please log in again',
        xianshangrenshu: 'number of people online',
    },
    kefu: {
        caozuozhinan: 'operation guide',
        xianshangkefu : 'telegram service',
        wx: 'wechat',
        tousudianhua: 'complaint hotline'
    },
    login: {
        username_placeholder: 'please enter the account number *',
        username_alert: 'please enter the account number',
        password_placeholder: 'please enter the password *',
        password_alert: 'please enter the password',
        password_alert_length: 'password must be greater than 6 digits',
        wangjimima: 'forgot password',
        zhuce: 'register now',
        denglu_btn : 'Login',
        wangji: ['wechat','online customer service']
    },
    zhuce: {
        top_right: 'existing account, Download app',
        username_placeholder: 'please enter the account number *',
        username_alert: 'please enter the account numbe',
        password_placeholder: 'please enter the password *',
        password_alert: 'please enter the password',
        password_alert_length: 'password must be greater than 6 digits',
        password_alert_buyizhi: 'the two passwords are inconsistent',
        password2_placeholder: 'please enter the password again *',
        password2_alert: 'please enter the password again',
        qk_password_placeholder: 'please enter the withdrawal password *',
        qk_password_alert: 'please enter the withdrawal password',
        qk_password_alert_length: 'withdrawal password must be greater than 6 digits',
        phone_placeholder: 'please enter your mobile number *',
        phone_alert: 'please enter your mobile number',
        code_placeholder: 'please enter the verification code *',
        code_alert: 'please enter the verification code',
        huoqu_btn: 'get verification code',
        index: 'home',
        zhuce: 'registration'
    },
    index: {
        top: ['british virgin islands',' operation of the group','philippines (pagcor)','(bvi) certification'],
        nav:['home','Leisure','recharge','withdrawal','lottery',' electronic','video','chess','Sports','fishing','language'],
        youxi:['do you need to transfer in the amount for [show_title]?','Current balance','being recovered...','Please enter the amount','all','directly enter the game','immediately enter the game']
    },
    bottom:{
        nav: ['activity center','online','home','quota','mine']
    },
    gerenzhongxin: {
        xiugaimima: 'modify password',
        lingqufuli: 'receive daily benefits',
        dailizhongxin: 'agent center',
        zhuzhanghuyue:'master account balance',
        benyuexiazhuzonge: 'total bet amount of this month',
        chongzhi: 'recharge',
        fanshui: 'return water',
        tixian: 'withdrawal',
        xiaoxi: 'message',
        fulihuodong: 'welfare activities',
        baobiao: 'report',
        shishifanshui : 'real time water return',
        shishifanshui_str : 'self code washing, real-time settlement, please try again after the bet meets the standard'
    },
    xiaoxi: {
        title: 'personal information',
        table: ['subject','content','sending time']
    },
    gerenxinxi: {
        title: 'personal information',
        bangdingshouji : 'bind mobile phone',
        xiugaizhifumima: 'modify payment password'
    },
    bangding: {
        title: 'bind mobile phone',
        yibangding: 'bound mobile number',
        shoujihao: 'mobile number',
        yanzhengma: 'verification code',
        jiumima: 'old password',
        xinmima: 'new password',
        querenmima: 'confirm password'
    },
    jilv: {
        cunkuan: 'deposit',
        tixian: 'withdrawal',
        touzhu: 'betting',
        fanshui: 'return water',
        erdu: 'quota conversion',
        riqi_title: 'select date',
        riqi: ['today','2days','3days','5days','7days','30days'],
        zongchenggongtixian: 'total successful recharge',
        tixianchenggong: 'withdrawal succeeded',
        cunkuan_tab: ['amount','status','time'],
        touzhu_tab: ['playing method','betting','winning bonus','time'],
        eduzhuanhuan_tab: ['amount','type','time']
    },
    erduzhuanhuan: {
        chonghuijinge: 'redrawn amount',
        zijinhuishou: 'fund recovery',
        zhongxinqianbao: 'center wallet',
        youxiqianbao: 'game wallet',
        zhuanzhang_placeholder: 'please enter the transfer amount',
        lijizhuanzhang: 'immediate transfer',
        alt1 : 'Game IDs on both sides cannot be consistent'
    },
    dailizhongxin: {
        tuiguanglianjie: 'promotion link',
        fuzhilianjie: 'copy link',
        fuzhichenggong: 'replication succeeded!',
        erweimatuiguang: 'QR code promotion',
        yongjinfafang: 'commission payment',
        huiyuangcunkuang :'member deposit',
        huiyuangtixian: 'member withdrawal',
        huiyuan: 'member',
        huiyuanshuying: 'member wins or loses',
        shenqinghuodong: 'apply for activity'
    },
    bangzhuzhongxin: {
        nav: ['activity center','customer service','quota conversion','personal center']
    }
}
